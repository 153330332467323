import React, { createContext, useContext, useState } from "react"
import useTranslateFunction from "./useTranslateFunction"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "ui_components/shadcn/ui/dialog"
import { Button } from "ui_components/shadcn/ui/button"

interface PromptContextType {
  openPrompt: (value: {
    title: string
    description: string | React.ReactNode
    onConfirm: () => void
    onCancel?: () => void
    primaryActionText?: string
    secondaryActionText?: string
    destructive?: boolean
    hideCancelButton?: boolean
    disableOverlayClose?: boolean
  }) => void
}

const PromptContext = createContext<PromptContextType>({
  openPrompt: () => {
    alert("Error: Context is not giving the open prompt. Wrap it further down.")
  },
})

export function PromptProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const [promptDetails, setPromptDetails] = useState<{
    title: string
    description: string | React.ReactNode
    onConfirm: () => void
    onCancel?: () => void
    primaryActionText?: string
    secondaryActionText?: string
    destructive?: boolean
    hideCancelButton?: boolean
    disableOverlayClose?: boolean
  }>({
    title: "",
    description: "",
    onConfirm: () => {},
  })
  const { t } = useTranslateFunction()

  function openPrompt(value: {
    title: string
    description: string | React.ReactNode
    onConfirm: () => void
    onCancel?: () => void
    primaryActionText?: string
    secondaryActionText?: string
    destructive?: boolean
    hideCancelButton?: boolean
    disableOverlayClose?: boolean
  }) {
    setPromptDetails(value)
    setIsOpen(true)
  }

  return (
    <PromptContext.Provider value={{ openPrompt }}>
      {children}
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open && promptDetails.disableOverlayClose) {
            return
          }
          setIsOpen(open)
        }}
      >
        <DialogContent showCloseButton={!promptDetails.disableOverlayClose}>
          <DialogHeader>
            <DialogTitle>{promptDetails.title}</DialogTitle>
          </DialogHeader>
          <DialogDescription className="text-base">
            {promptDetails.description}
          </DialogDescription>
          <DialogFooter>
            {!promptDetails.hideCancelButton && (
              <Button
                variant="secondary"
                onClick={() => {
                  promptDetails.onCancel && promptDetails.onCancel()
                  setIsOpen(false)
                }}
                className="mt-2 sm:mt-0"
              >
                {promptDetails.secondaryActionText || t("usePrompt_Cancelar")}
              </Button>
            )}
            <Button
              variant={promptDetails.destructive ? "destructive" : "default"}
              onClick={() => {
                promptDetails.onConfirm()
                setIsOpen(false)
              }}
            >
              {promptDetails.primaryActionText || t("usePrompt_Confirmar")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </PromptContext.Provider>
  )
}

export function usePrompt() {
  return useContext(PromptContext)
}
